<app-custom-dialog-popup [header]="'Title'" [style]="dialogStyle$()" [visible]="dialogVisible()"
    [styleClass]="dialogStyleClass()" [maximizable]="false">

    <ng-container dialogHeader>
        <div
            class="w-full inline-flex align-items-center text-white font-semibold justify-content-center gap-2 fluid-title">
            {{dialogHeader$()}}
        </div>
    </ng-container>

    <ng-container *ngIf="editGroupState() === EditGroupState.EditAvailability">
        <div class="md:col-8 mx-auto px-2">
            <app-student-availability-checkboxes [times]="times" [compact]="false" [(selectedFlags)]="selectedFlags">
            </app-student-availability-checkboxes>
        </div>
    </ng-container>

    @defer (on viewport) {



    <ng-container *ngIf="step() === 1">
        <app-student-group-selection-suggestion-text-step
            (buttonClicked)="onSuggestionTextButtonSelected($event)"></app-student-group-selection-suggestion-text-step>
    </ng-container>

    <ng-container *ngIf="step() === 2">

    <div class="">
        
    <ng-container *ngIf="editGroupState() === EditGroupState.AfterCreateSuccess">
        <div class="align-items-center flex justify-content-center w-full ng-star-inserted">
            <div class="surface-card border-round flex flex-column align-items-center flex justify-content-center sm:flex-row shadow-2 w-full">
                <div class=" flex align-items-center justify-content-center py-3 px-5"><img
                        src="/assets/images/graphic/universe-planet-right-center-eyes.webp" alt="Image"
                        class="mx-auto block w-full"></div>
                <div class="py-3 px-2 flex flex-column align-items-start">
                    <div class="text-900 font-medium mb-2 text-xl">Group successfully Created!</div>
                    <p class="mt-0 mb-4 p-0 line-height-3"> Thank you for helping us enhance your students' learning experience!</p>
                    
                    <p-button label="Create another group"
                    styleClass="p-button-rounded p-button-outlined submit-btn text-white" [icon]="'pi pi-plus'"
                    [rounded]="true" [outlined]="true" (click)="onGroupStateChanged(EditGroupState.CreateGroup)" 
                    ></p-button>
                </div>
            </div>
        </div>
    </ng-container>

        <ng-container *ngIf="editGroupState() === EditGroupState.EditMembers || !editMode() && editGroupState() !== EditGroupState.AfterCreateSuccess">

            <app-student-group-selection-members [editGroupState]="editGroupState()" [editMode]="editMode()"
                (groupStateChanged)="onGroupStateChanged($event)"
                (groupItemChanged)="onGroupItemChanged($event)"
                ></app-student-group-selection-members>

        </ng-container>

        <!-- General Error Message -->
        <div *ngIf="generalService.errorDataSignal" class="p-1 my-2">
            <app-form-field-validation-message [severity]="Severity.Danger" styleClass="w-full justify-content-start"
                [text]="generalService.errorDataSignal">
            </app-form-field-validation-message>
        </div>

        <ng-container *ngIf="editGroupState() === EditGroupState.EditAvailability">
            <div class="flex flex-column align-items-center justify-content-center w-full text-center gap-3">
                <p-button label="Save Group Availability"
                    styleClass="p-button-rounded p-button-outlined  p-purple w-16rem" [icon]="'pi pi-plus'"
                    [rounded]="true" [outlined]="true" (click)="backToViewGroup()" severity="danger"></p-button>
            </div>
        </ng-container>

        <ng-container *ngIf="deleteGroupContent() && editMode()">
            <div class="px-3 py-3">
                <div class="p-1 mt-2">
                    <h3 class="primary-purple-color m-0 text-center">Are you sure you want to delete the group?</h3>
                </div>
            </div>
            <ng-container dialogFooter>
                <div class="flex flex-column align-items-center justify-content-center w-full text-center gap-2">
                    <p-button (click)="deleteGroupStepActionSelected('yes')" styleClass="w-16rem" label="Yes"
                        [rounded]="true" [outlined]="true" severity="success" />
                    <p-button (click)="backToViewGroup()" styleClass="w-16rem" label="No" [rounded]="true"
                        [outlined]="true" severity="danger" />
                </div>
            </ng-container>
        </ng-container>

        <div  *ngIf="canGoBack$()" class="flex flex-column align-items-center justify-content-center w-full text-center gap-3 mt-2 mb-4">
            <p-button label="Back" styleClass="w-16rem" (click)="backToViewGroup()"
                icon="pi pi-chevron-left" [rounded]="true" severity="help" [outlined]="true" />
        </div>

    </div>
    </ng-container>

    <ng-container *ngIf="editMode() && editGroupState() === EditGroupState.None || editGroupState() === EditGroupState.AfterEditSuccess">
        <ng-container dialogFooter>

            <div class="align-items-center flex justify-content-center w-full ng-star-inserted">
                <div class="border-round flex flex-column align-items-center flex justify-content-center 
                sm:flex-row w-full py-3 px-5">
                    <div class=" flex align-items-center justify-content-center p-2"><img
                            src="/assets/images/graphic/universe-planet-right-center-eyes.webp" alt="Image"
                            class="mx-auto block w-full w-4rem"></div>
                    <div class=" flex flex-column align-items-start">
                        <div class="text-900 font-medium mb-2 text-xl">{{this.generalService.getGroupDetails(studentGroupItem())}}</div>
                        <app-student-group-row-display [studentGroupItem]="studentGroupItem()"></app-student-group-row-display>

                    </div>
                </div>
            </div>

            <div class="flex flex-column align-items-center justify-content-center w-full text-center gap-3 my-3">
                <p-button label="Edit Group Members" styleClass="p-button-rounded p-button-outlined  p-purple w-16rem"
                    icon="pi pi-plus" (click)="editGroupState.set(EditGroupState.EditMembers)"
                    severity="primary"></p-button>
                <p-button label="Edit Group Availability"
                    styleClass="p-button-rounded p-button-outlined  p-purple w-16rem" icon="pi pi-clock"
                    (click)="editGroupState.set(EditGroupState.EditAvailability)" severity="primary"></p-button>

                <p-button label="Delete Group" styleClass="p-button-rounded p-button-outlined w-16rem"
                    icon="pi pi-delete-left" (click)="deleteGroupStepSelected()" severity="danger"></p-button>
            </div>
        </ng-container>
    </ng-container>

    } @placeholder {
    <span>loading</span>
    }

</app-custom-dialog-popup>