import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CarouselPageEvent } from 'primeng/carousel';

@Injectable({
  providedIn: 'root'
})
export class CarouselService implements OnDestroy {

  carouselIndex = 0;
  numVisible = 4;
  responsiveOptions!: { breakpoint: string; numVisible: number; numScroll: number; }[];
  selectedItemIndex: number = -1; // Track the index of the selected item
  private resizeSubscription!: Subscription;

  constructor() {
    this.setupResponsiveOptions();
    this.setupResizeListener();
  }

  onCarouselIndexChange(event: CarouselPageEvent) {
    this.carouselIndex = event.page as number;
  }

  private setupResponsiveOptions() {
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: this.numVisible,
        numScroll: this.numVisible
      },
      {
        breakpoint: '991px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  private setupResizeListener() {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        startWith(window.innerWidth) // Emit the initial width
      )
      .subscribe(screenWidth => {
        this.updateNumVisible(screenWidth);
      });
  }

  private updateNumVisible(screenWidth: number) {
    if (screenWidth >= 1200) {
      this.numVisible = 4; // Adjust this as needed
    } else if (screenWidth >= 992) {
      this.numVisible = 3;
    } else if (screenWidth >= 768) {
      this.numVisible = 1;
    } else {
      this.numVisible = 1;
    }
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }
}