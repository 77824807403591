<div class="col-12 cursor-pointer text-center primary-purple-color m-auto w-full align-items-center"
    (click)="selectable ? toggleSelection(item): null">
    <div [ngClass]="{'selected': isSelected, 'h-5rem': !imageProperty, 'disabled': isDisabled}"
        class="{{selectable ? 'group-item border-1' : ''}} p-2 py-3 border-round-xl flex flex-column align-items-center justify-content-center relative">

        <p-checkbox *ngIf="selectionMode === 'multiple' && selectable" [binary]="true" 
        [ngModel]="isSelected"
            binary="true" styleClass="primary-availability-dropdown outlined absolute pointer-events-none">
        </p-checkbox>

        
        <!-- <p-checkbox *ngIf="selectionMode === 'multiple' && removeable" [binary]="true" 
        [ngModel]="isSelected"
            binary="true" styleClass="removeable-checkbox outlined absolute pointer-events-none">
        </p-checkbox> -->

        <ng-container *ngIf="!studentGroupMode">
            <img *ngIf="languagesMode; else defaultImage" [src]="generalService.getImageUrlForLanguage(item.name)"
                [ngClass]="{'filter-brightness': isSelected && item.new}" alt="img" class="w-2rem pb-2" />
            <ng-template #defaultImage>
                <img *ngIf="imageProperty" [src]="'assets/images/dummy/astronaut-01.png'"
                    [ngClass]="{'filter-brightness': isSelected && item.new}" alt="img" class="w-2rem pb-2" />
            </ng-template>
        </ng-container>

        <p class="m-0 text-center capitalize">{{prepareNameProperty(item)}}</p>

        <!-- Add student group handling if necessary -->

    </div>
</div>